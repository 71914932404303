<template>
  <div class="attachments">
    <Header back="返回" title="合同列表" index="首页" titleOne="合同管理" beforeTitle="详情" />
    <div class="content">
      <h1>合同详情</h1>
      <el-form
        :rules="contarctRule"
        ref="contarctRule"
        :model="contarctform"
        label-width="80px"
        :label-position="labelPosition"
      >
        <ul>
          <li>
            <span>企业名称</span>
            <p>{{ contarctform.company_name ? contarctform.company_name : '-'}}</p>
          </li>
          <li>
            <span>合同名称</span>
            <p>{{ contarctform.contract_name ? contarctform.contract_name : '-'}}</p>
          </li>
          <li>
            <span>合同编号</span>
            <p>{{ contarctform.ht_num ? contarctform.ht_num : '-'}}</p>
          </li>
          <li>
            <span>签约时间</span>
            <p>{{ contarctform.down_time ? contarctform.down_time : '-'}}</p>
          </li>
          <li>
            <span>合同时间</span>
            <p>{{ contarctform.start ? contarctform.start :'-'}} 至 {{ contarctform.end?contarctform.end:'-' }}</p>
          </li>
          <li>
            <span>合同金额</span>
            <p>{{ contarctform.amount ? contarctform.amount : "-" }}</p>
          </li>
          <li>
            <span>渠道商</span>
            <p>{{ contarctform.channel ? contarctform.channel : '-' }}</p>
          </li>
          <li>
            <span>联系人</span>
            <p>{{ contarctform.contact?contarctform.contact:'-' }}</p>
          </li>
          <li>
            <span>合同说明</span>
            <p class="contarct_p">{{contarctform.explain?contarctform.explain:'-'}}</p>
            <!-- <el-input
              type="textarea"
              v-model="contarctform.explain"
              :autosize="{ minRows: 5, maxRows: 5 }"
              style="width: 85%; display: inline-block"
              disabled
            ></el-input>-->
          </li>
          <li>
            <span>状态</span>
            <p v-if="!contarctform.status">未提交</p>
            <!-- <p v-if="contarctform.status == 0">未提交</p> -->
            <p v-if="contarctform.status == 1">已提交</p>
            <p v-if="contarctform.status == 2">已通过</p>
            <p v-if="contarctform.status == 3">未通过</p>
            <p v-if="contarctform.status == 4">已流转</p>
            <p v-if="contarctform.status == 3">已分配</p>
          </li>
        </ul>
        <h1 style="margin-top:50px">合作项目</h1>
        <el-table :data="contarctform.project" style="width: 100%" :key="Math.random()">
          <el-table-column width="50" v-if="false">
            <template slot="header">
              <button @click="addLine" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                v-if="contarctform.project.length > 0"
                @click="handleDelete(scope.$index, scope.row)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <el-table-column label="项目类型">
            <template slot-scope="scope">
              <div>{{type.find((item)=>item.id == scope.row.product_type).name}}</div>
              <!-- <el-select
                v-model="scope.row.product_type"
                placeholder="请选择"
                @change="
                  (val) => {
                    getproductId(val, scope.$index);
                  }
                "
                disabled
              >
                <el-option v-for="item in type" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>-->
            </template>
          </el-table-column>
          <el-table-column label="项目">
            <template slot-scope="scope">
              <div>{{xm_name[scope.$index]}}</div>
              <!-- <el-select v-model="scope.row.product_id" placeholder="请选择" disabled>
                <el-option
                  v-for="item in productId[scope.$index]"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>-->
            </template>
          </el-table-column>
          <el-table-column label="是否附加项目">
            <template slot-scope="scope">
              <span v-if="!scope.row.is_additional">否</span>
              <span v-if="scope.row.is_additional">是</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <el-button class="cz info edit" @click="delDetail(scope.row.id)" type="text">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
  </div>
</template>
<script>
import site from "../../components/global.vue";
export default {
  data () {
    return {
      xm_name: [],
      userSite: site.userSite,
      labelPosition: "top",
      contarctform: {
        company_name: "",
        contract_name: "",
        ht_num: "",
        down_time: "",
        start: "",
        end: "",
        amount: "",
        channel: "",
        contact: "",
        tel: "",
        explain: "",
        project: [],
        com_id: "",
      },
      results: [],
      timeout: null,
      contarctRule: {},
      type: [],
      productId: new Array(),
      list: [],
      token: '',
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  created () {
    this.token = localStorage.getItem('token')
    console.log(this.contarctform.project.product_type, 22222)
  },
  mounted () {
    this.getType();
    if (this.meber_id) {
      this.axios
        .get("/api/contract/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res);
          res.data.project.forEach((item, index) => {
            console.log(item, index);
            item.product_type = parseInt(item.product_type);
            this.getproductId(item.product_type, index);
          });
          this.contarctform = res.data;
        });
    }
  },

  methods: {
    delDetail (id) {
      this.$router.push({ path: '/contract/edit', query: { id: id } })
    },
    getType () {
      this.axios.get("/api/policy/select_product_type", { params: { token: this.token } }).then((res) => {
        this.type = res.data;
      });
    },
    getproductId (id, param) {
      console.log(param);
      this.axios
        .get("/api/policy/select_product", {
          params: {
            type: id,
          },
        })
        .then((res) => {
          this.productId[param] = res.data;
          this.$forceUpdate();
          this.xm_name.push(this.productId[param].find((item) => item.id == this.contarctform.project[param].product_id).title)
          // console.log(this.xm_name, 666)
        });
    },
    addLine () {
      //添加行数
      var newValue = {};
      this.contarctform.project.push(newValue);
    },
    handleDelete (index) {
      //删除行数
      this.contarctform.project.splice(index, 1);
    },
    save () {
      //这部分应该是保存提交你添加的内容
      console.log(JSON.stringify(this.contarctform.project));
    },
  },
};
</script>

<style scoped="scoped">
.attachments ul li {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.content ul li span {
  color: #404040 !important;
  margin-right: 20px;
  /* float: left; */
  width: 80px;
  display: inline-block;
}
.attachments ul li p {
  display: inline-block;
  width: 70%;
  margin: 0;
  color: #909399;
  display: flex;
  flex-wrap: wrap;
}
.info {
  padding: 7px 11px !important;
}
</style>
